import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";

const PrivacyPolicyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      policy: file(relativePath: { eq: "privacy-policy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      markdownRemark(frontmatter: { path: { eq: "privacy-policy" } }) {
        html
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <SimplePage
        title="Privacy Policy"
        description="Again, so we don't get sued."
        fluid={data.policy.childImageSharp.fluid}
      >
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </SimplePage>
    </Layout>
  );
};

export default PrivacyPolicyPage;
